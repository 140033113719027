.ticket-view {
    .card {
        border: none;
    }

    .card-header {
        align-self: center;
        color: #c51c15;
        font-size: 22px;
        background: transparent;
        border: 0;
        font-weight: 700;
    }

    .chat-message {
        border: 1px solid lightgray;
        margin-bottom: 15px;
        border-radius: 8px;
        padding: 10px;
        width: 75vw;
        margin-right: 30px;
        font-size: 17px;
    }

    span.date {
        width: 100%;
        display: flex;
        position: relative;
        right: 0;
        justify-content: right;
        font-size: 10px;
        color: grey;
    }

    .inputSendMessage {
        width: 79vw;
        border-radius: 30px;
        border: 1px solid lightgray;
        margin-inline: 1rem;
        padding: 15px;
        margin-bottom: 20px;
    }
    .BottomResponseTicket {
        position: fixed;
        bottom: 0;
        background-color: white;
    }
    
    .imageDisplay {
        margin-top: 1%;
        font-size: 35px;
        right: -7%;
        position: absolute;
        color: grey;
    }
    .preview-image-container {
        display: flex;
        place-content: center;
    }
    .chat-conversation {
        height: 65vh;
        overflow: scroll;
    }
}