@import 'MediaQueries';

.Header {
    position: fixed;
    right: 0;
    border-bottom: 1px solid #9A1712;
    height: 7vh;
    background-color: white;
    z-index: 2;
    width: 95%;

    @media screen and (min-width: 700px) {
        top: 0;
    }

    @media only screen and (max-width: 768px) {
        top: 0;
        width: 100%;

    }

    .profileView {
        // text-align: left;
        display: flex;
        place-items: center;
        justify-content: right;
        position: absolute;
        right: 4%;

        img {
            width: 6vh;
            height: 6vh;
            border-radius: 50%;
            margin-top: 1px;
            border: 1px solid purple;

        }

        p {
            color: #9A1712;
            font-size: 10px;
            line-height: 0;
            margin-right: 1rem;
            margin-left: 0.2rem;
        }
    }





}
.desk-span {
    padding-inline: 5px;
    border-radius: 50%;
    height: 18px;
    font-size: 12px;
    width: 18px;
    position: absolute;
    top: 2px;
    left: 95px;
}
.bonus-span {
    font-size: 10px;
    position: absolute;
    left: 20px;
    top: 2px;
    border-radius: 4px;
    padding: 2px;
}
.webView {
    @include phone {
        display: none;
    }

    // text-align: left;
    display: flex;
    place-items: center;
    justify-content: right;
    position: absolute;
    right: 2%;
    top: 2%;
    width: 75vw;

    img {
        width: 6vh;
        height: 6vh;
        border-radius: 50%;
        margin-bottom: 1rem;

    }

    p {
        color: #9A1712;
        font-size: 10px;
        line-height: 0;
        margin-right: 1rem;
        margin-left: 0.2rem;
    }
}

.mobileView {
    @include phone {
        display: flex;

    }

    @include miniTablet {
        display: none;
    }

    @include desktop {
        display: none;
    }

    @include tabletLandscape {
        display: none;
    }

    @include tablet {
        display: none;
    }
}

.imageProfiles {
    width: 5vh !important;
    height: 5vh !important;
    border-radius: 50%;
    margin-bottom: 10px !important;
}

.imageProfilesParagraph {
    color: black !important;
    font-size: 11px !important;
    line-height: 0 !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
}