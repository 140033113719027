@import 'MediaQueries';

@include phone {
    .helpDesk {
        .youtube-image-side {
            float: right;
            margin-left: 10px;
            width: 26%;
            position: relative;
            bottom: 0px;
        }

        .accordion-collapse.collapse.show {
            .youtube-image-bottom-left {
                // position: absolute;
                // bottom: 10px; 
                // left: 10px; 
                display: none;
            }

        }

        img.youtube-image-bottom-left {
            width: 2%;
        }

        .card {
            border: 2px solid lightgray;
            margin-bottom: 1rem;
            padding: 0;
            border-radius: 14px;
            margin-inline: 30px;


            .card-body {
                padding-inline: 8px;
                margin: 0;
                padding-top: 1px;
                padding-bottom: 0px;
                font-size: 12px;
                color: gray;

                .ticketType {
                    padding: 3px;
                    border-radius: 7px;
                    width: 25vw;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 800;
                    margin-bottom: 5px;
                    font-size: 12px;

                }

                .counterSpan {
                    font-weight: 700;
                    padding: 2px;
                    color: white;
                    border-radius: 50%;
                    height: 75%;
                    width: 4vw;
                    text-align: center;
                    margin-right: 4px;
                }
            }
        }

        .BottomButtons {
            position: fixed;
            // bottom: 30px;
            bottom: 15px;

            right: 10px;
            border-radius: 15px;
        }

        .mt-2.accordion {
            margin-inline: 1rem;
        }




        button.accordion-button {
            font-weight: 600;
            font-size: 18px;
        }

        .accordion-item {
            border: 1px solid grey;
            margin-bottom: 1rem;
            border-radius: 20px;
        }

        .accordion-button:not(.collapsed) {
            color: black;
            background-color: transparent;
            box-shadow: 0;
            outline: none !important;
        }

        .accordion-button::after {
            flex-shrink: 0;
            width: var(--bs-accordion-btn-icon-width);
            height: var(--bs-accordion-btn-icon-width);
            margin-left: auto;
            content: "";
            background-image: var(--bs-accordion-btn-icon);
            background-repeat: no-repeat;
            background-size: var(--bs-accordion-btn-icon-width);
            transition: var(--bs-accordion-btn-icon-transition);
            display: none !important;
        }

        .nav-tabs .nav-link {
            background-color: transparent;
            color: lightgrey !important;
            border: 0;
            border-radius: 0;
            font-size: 22px;
            display: block;
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            background-color: transparent;
            color: black !important;
            border-bottom: 5.5px solid #9A1712 !important;
            border: 0;
            border-radius: 0 0 4px 4px;
            font-size: 18px;
        }

        ul.nav.nav-tabs {
            width: 100%;
            display: flex;
            justify-content: space-around;
            border: 0;
            margin-top: -30px;
        }

        .nav-item {
            font-size: 16px;
            color: black;
            text-align: center;
            width: 40%;
            border-radius: 0.25rem;
            padding: 0.5rem 1rem;
            margin-bottom: 0.5rem;
            display: flex;
        }

    }
}

.faqsearchButton {
    border: 2px solid black;
    width: 95%;
    margin-inline: 15px;
    border-radius: 35px;
    padding: 15px;
    font-size: 20px;

    input#faq {
        padding: 2px;
        background: transparent;
        font-size: 15px;
        border: 0px solid transparent;
    }
}