@import 'MediaQueries';

.mainCampaign {


  .pledgeDisplay {
    width: 88%;
    right: 0;
    position: absolute;
    overflow-x: hidden;

    @include phone {

      display: none;
    }

    .pie-chart-container .apexcharts-canvas {
      position: absolute;
      margin-top: -1.3rem;
      right: 13%;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
      color: white;
      position: absolute;
      background: transparent;

      top: 20%;
      left: 3%;
      text-shadow: 2px 2px 3px black;

    }

    .categoryCampaign {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      color: white;
      position: absolute;
      top: 16%;
      left: 5%;
      text-shadow: 2px 2px 3px black;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
    }



    .progress-bar {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      span {
        font-size: 1.2rem;
        font-weight: bold;
        margin-right: 1rem;
      }


    }

    .pie-chart-container {
      width: 65%;
    }

    .give-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      .give-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #ddd;
        padding: 1rem;
        width: calc(50% - 1rem);

        p {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }

        span {
          font-size: 1.2rem;
        }
      }
    }

    .doughnut-chart {
      width: 150px;
      height: 150px;
      margin: 0 auto;
    }

    a {
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0.5rem 2rem;
      background-color: #4caf50;
      color: #fff;
      text-decoration: none;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #3e8e41;
      }
    }

    .TopDisplayImage {
      // width: 100vw;
      width: 87vw;

      height: 50vh;
      // object-fit: cover;

    }

    .TopDisplayImage::before {
      content: "";
      background-color: rgba(29, 41, 62, 0.6);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;


    }

    .TopDisplayCard {
      border: none;
      box-shadow: 1px 0px 9px rgb(43 2 2 / 55%);
      margin-top: -5rem;
      background-color: #7c0000a8;

      .card-body {
        display: flex;
      }

    }

    .BottomDisplayCard {
      margin-top: 1rem;
      border: none;
    }

    .percentage-text {
      position: absolute;
      top: 40%;
      right: 19%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      font-weight: bold;
    }

    .pie-chart-campaign {
      .percentage-text {
        position: absolute;
        top: 32%;
        right: -2%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }

  .pledeDisplayMobile {
    max-height: 80vh;

    @include phone {

      display: none;
    }

    .TopDisplayImage {
      width: 100%;
      height: 30vh;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
    }

    .campaign-info {
      padding: 1rem;

      .campaign-header {
        h5 {
          text-align: left;
          font-size: 0.9rem;
          font-weight: 700;
        }



        .donate-button {
          color: gray;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .my-pledge {
        font-size: 15px;
        margin-top: 0.5rem;
        font-weight: 500;
        text-align: left;

      }

      .amount {
        font-size: 18px;
      }

      .days-left {
        font-size: 13px;
        font-weight: 500;
      }

      .campaign-details.card {
        // box-shadow: 8px 2px 12px 2px lightgrey;
        box-shadow: 1px 5px 9px 8px lightgrey;
        margin-top: 0.5rem;
        border: none;
        border-radius: 14px;
        margin-bottom: 0.8rem;

        .card-body {
          padding-top: 0.9rem;
          padding-bottom: 0.5rem;
          padding-inline: 0.8rem;
          display: block;
        }
      }




      .progress {
        width: 100%;
        height: 7px;
        background-color: #ddd;
        margin-bottom: 0.3rem;

        .progress-fill {
          height: 100%;
          background-color: #9A1712;
          width: 53%;
          border-radius: 20px;
        }
      }

      .profileImage {
        width: 8vw;
        height: 4vh;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 5px;
      }

      .about {
        h5 {
          text-align: left;
          font-size: 17px;
          margin-top: 5px;
          font-weight: 700;
        }

        p {
          text-align: left;
          font-size: 14px;
          color: black;
          opacity: 80%;

        }

      }

      .originator {
        h5 {
          text-align: left;
          font-size: 17px;
          font-weight: 800;
        }

        span {
          font-size: 14px;
          align-self: center;
        }
      }



    }


  }

  .actions {
    @include phone {

      display: none;
    }

    display: flex;
    position: fixed;
    bottom: 0;
    background-color: white;

    button {
      margin: 0.5rem;
      border-radius: 14px;
      font-weight: bold;
      height: 7vh;
      width: 43vw;
    }
  }



}

.modal-dialog.modal-dialog-centered {
  max-width: 33vw !important;

  @include tablet {
    max-width: 50vw !important;
  }
  @include miniTablet {
    max-width: 75vw !important;
  }

  @include phone {

    max-width: 100vw !important;
    margin: 0 auto;
  }
}


form.makePledgeForm {
  margin-top: -0.3rem;

  p {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  button {
    width: 46%;
    margin-bottom: 1rem;
    font-weight: 500;
  }


  .kshInput {
    width: 13%;
    color: #9A1712;
    border: 1.5px solid #9A1712;
    border-radius: 4px;
    margin-right: 0.5rem;
    outline-color: transparent;
    margin-bottom: 0.5rem;

  }

  textarea {
    resize: vertical;
    width: 27.3vw;
    border-radius: 7px;
    border: 1.5px solid #9A1712;
    padding: 0.2rem;
    outline-color: transparent;

    @include phone {

      width: -webkit-fill-available;

    }
  }

  input.inputNumberInstallement {
    width: 100%;
    border-radius: 5px;
    outline-color: transparent;
    padding: 0.2rem;
    border: 1.5px solid #9A1712;
  }

  input[type=checkbox] {
    margin-right: 6px;
    height: 20px;
    width: 20px;
    margin-top: 0rem;
  }

  input:checked {
    accent-color: #9A1712;
    background-color: #9A1712;
  }

  span.checked {
    margin-right: 0.25rem !important;
    top: -5px;
    position: relative;
  }

  .amountKshInput {
    border: 1.5px solid #9A1712;
    border-radius: 4px;
    outline-color: transparent;
    margin-bottom: 0.5rem;
    width: 84%;

    @include phone {

      height: 5vh;
    }

  }

  select.form-select {
    border: 1.5px solid #9A1712;
    margin-bottom: 0.5rem;
    outline-color: transparent;

  }
}

input.input-field {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.3rem;
  border: 1.5px solid #9A1712;
  border-radius: 5px;
  outline-color: transparent;
}

.calendarShow {
  width: 10%;
  position: relative;
  margin-left: -13%;
  background: transparent;
  border: 0;
  color: transparent;
  opacity: 0;
}

.calendarDate {
  // left: 25%;
  // width: 2vw;
  // font-stretch: expanded;
  // height: 5vh;
  // background: transparent;
  // opacity: 0;
  // position: absolute;
  left: 3%;
  width: 8vw;
  font-stretch: expanded;
  height: 5vh;
  background: transparent;
  opacity: 0;
  position: absolute;
}
.input-container input {
  // border: none;
  // outline: 0;
  // padding: 0;
  // position: relative;
  // width: 100%;
  // opacity: 0;
  border: none;
    outline: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    margin-top: 0rem;
    margin-left: -14rem;
    opacity: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  opacity: 0;
}
.footerModalAccount {
  position: relative;
  bottom: 0px;
  margin-left: -0.5rem;
  width: 105vw;
  top: 8px;

  .makePledgeForm {
    button {
      height: 7vh;
      border-radius: 10px;

    }
  }

}

.AccountsModal {
  .modal-dialog {
    width: 70vw;
    margin-inline: 4rem;
    display: flex;
    align-items: center;
    min-height: calc(100% - 0.6rem);


    .modal-content {
      border-radius: 20px;

      input {
        border-radius: 10px;
        height: 6vh;
      }

      button {
        width: 45vw;
        height: 6vh;
        border-radius: 10px;
      }

      .form-select {
        height: 6vh;
        margin-bottom: 1.5rem;
      }

      span {
        margin-bottom: 1.5rem;

      }
    }


  }
}

.pledgeDataStatement {
  background-color: white;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  box-shadow: 1px 2px 3px lightgray;
}


.react-calendar__tile--now {
  background: transparent !important;
  border-radius: 50%;
  color: black;
}

.react-calendar__tile.react-calendar__month-view__days__day>.continueTodayStart {}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend:has(>.continueTodayStart) {
  color: white;
  background-color: #9A1712;
  border-radius: 50%;
  margin-top: 0.2rem;
  height: 7vh;
  max-width: 4vw !important;
  margin-left: 0.5rem;

  @include phone {

    height: 4vh;
    max-width: 8vw !important;
  }

}

.react-calendar__tile.react-calendar__month-view__days__day:has(>.continueTodayStart) {
  color: white;
  background-color: #9A1712;
  border-radius: 50%;
  margin-top: 0.2rem;
  height: 7vh;
  max-width: 4vw !important;
  margin-left: 0.5rem;

  @include phone {

    height: 4vh;
    max-width: 8vw !important;
  }
}

.react-calendar__tile.react-calendar__month-view__days__day:has(>.activeTodayStart) {
  color: white;
  background-color: #6060cd !important;
  border-radius: 50%;
  margin-top: 0.2rem;
  height: 7vh;
  max-width: 4vw !important;
  margin-left: 0.5rem;

  @include phone {

    height: 4vh;
    max-width: 8vw !important;
  }

}

.topDisplayCard {
  border: none;
  width: 31%;
  color: white;

  .card-body {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 11px;
      margin-bottom: 0;
    }

    span {
      font-size: 10px;
    }
  }


}

.endDate {
  background: #7db87d;
  box-shadow: 1px 2px 1px 2px #aac0b6;

}

.nextDate {
  background: #7296df;
  box-shadow: 1px 2px 1px 2px #7296df;

}

.frequencyDate {
  background: #b87d7d;
  box-shadow: 1px 2px 1px 2px #b87d7d;


}

.TopDisplayMetrics {
  margin-top: 1rem;
  border: none;
  border-radius: 15px;
  background-color: #9A1712;
  display: flex;
  justify-content: space-between;

  .card-body {
    display: flex;
    justify-content: space-between;
  }

  span {
    color: wheat;
  }

  p {
    color: white;
  }

}

.react-calendar {
  margin-top: 1rem;
  width: 36vw !important;
  border: none !important;

  @include phone {

    width: 90vw !important;
  }
}

p.topMobileShare {
  position: relative;
  margin-top: -2rem;
  background: white;
  border-radius: 50%;
  box-shadow: 1px 3px 6px lightgray;
  display: flex;
  float: right;
  padding: 1rem;
  height: 6vh;
  align-items: center;

}

.btn-danger {
  background-color: #9A1712 !important;
}

.text-danger {
  color: #9A1712 !important;
}