@import 'MediaQueries';

.pledgeDisplay {
  width: 88%;
  right: 0;
  position: absolute;
  overflow-x: hidden;

  @include phone {
    display: none;
  }

  @include tablet {
    width: 100%;
  }



  .BottomDisplayCard {
    margin-top: 1rem;
    border: none;

    .pie-chart-container {
      width: 100%;

      .apexcharts-canvas {
        position: absolute;
        margin-top: -1.3rem;
        right: 0%;

        @include tablet {
          right: -15%;
        }

        @include tabletLandscape {
          margin-top: -0.3rem;
          right: -30%;
        }

        @include miniTablet {
          margin-top: -0.3rem;
          right: -24%;
        }

        @include desktop {
          right: -30%;
          margin-top: -0.3rem;

        }

        @include desktopMedium {
          // right: -40%;
          right: -40px;
          margin-top: 2px;
          // right: -15px;
          // top: -15px;


        }

        @media (min-width: 770px) and (max-width: 900px) {
          right: -65%;

        }
      }
    }
  }



  .pledgeCardChart {
    width: 100%;

    .pie-chart-container {
      width: 100%;

      .apexcharts-canvas {
        position: absolute;
        margin-top: -1.3rem;
        right: 0%;

        @include tablet {
          right: -15%;
        }

        @include tabletLandscape {
          margin-top: -0.3rem;
          right: -30%;
        }

        @include miniTablet {
          margin-top: -0.3rem;
          right: -24%;
        }

        @include desktop {
          right: -30%;
          margin-top: -0.3rem;

        }

        @include desktopMedium {
          // right: -40%;
          right: 15px;
          top: -15px;


        }

        @media (min-width: 770px) and (max-width: 900px) {
          right: -65%;

        }
      }
    }
  }

  .topButtonPledge {
    width: auto;
    height: auto;
    top: 6%;
    right: 2%;
    position: absolute;
    border-radius: 6px;
    border: none;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: white;
    background: transparent;
    position: inherit;
    top: 14vw;
    left: 1%;
    text-shadow: 2px 2px 3px black;
  }

  .userProfileTop {
    width: 3vw;
    height: 7vh;
    top: 6%;
    left: 2%;
    position: absolute;
    border-radius: 50%;
    border: 2px solid white;
  }

  .categoryCampaign {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: white;
    position: absolute;
    top: 15%;
    left: 5%;
    text-shadow: 2px 2px 3px black;
  }

  p {
    line-height: 1.5;
  }

  .topRaised {
    position: absolute;
    top: 23%;
    // top: 32%;
    left: 3%;
    background-color: rgb(43 2 2 / 55%);
    color: white;
    padding: 1rem;
  }


  .progress-bar {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    span {
      font-size: 1.2rem;
      font-weight: bold;
      margin-right: 1rem;
    }


  }



  .give-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .give-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #ddd;
      padding: 1rem;
      width: calc(50% - 1rem);

      p {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      span {
        font-size: 1.2rem;
      }
    }
  }

  .doughnut-chart {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }


  a {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.5rem 2rem;
    // background-color: #4caf50;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      // background-color: #3e8e41;
    }
  }

  .TopDisplayImage {
    // width: 100vw;
    width: 87vw;

    height: 48vh;
    object-fit: cover;

    @include tablet {
      width: 100vw;
    }

  }


  .ReddemButtonWeb {
    align-self: center;
    width: 7vw;

    @include tablet {
      width: 12vw;
    }

  }


  .TopDisplayImage::after {
    content: "";
    background: rgb(32 32 32 / 28%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;


  }

  .leftFixedPledgeData {
    position: fixed;
    right: 0;
    top: 60%;

  }

  .TopDisplayCard {
    border: none;
    box-shadow: 1px 0px 9px rgb(43 2 2 / 55%);
    margin-top: -50px;
    height: 25vh;

    @include miniTablet {
      height: 15vh;

    }


    @include tablet {
      height: 20vh;
      zoom: 0.7;

    }

    // background-color: #7c0000a8;

    .card-body {
      display: flex;
    }

  }



  .percentage-text {
    position: absolute;
    top: 28%;
    right: 30px;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: bold;
  }

  .pie-chart-campaign {
    .percentage-text {
      position: absolute;
      top: 32%;
      right: -2%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}

.topDisplayMetrics {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: right;
  margin-top: -2.9rem;
  // background-color: transparent;
}

.redeemMpesa {
  display: flex;
  float: right;
  width: 15vw;
  position: relative;
  right: 36px;
  place-content: center;
}

.EditKshDisplay {
  width: 20%;
  height: 6vh;
  border-radius: 5px;

  @include phone {
    height: 3.8vh;
  }


}

.pledeDisplayMobile {
  max-height: 80vh;

  @include phone {
    display: block;
  }

  @include tablet {
    display: none;
  }

  @include miniTablet {
    display: none;
  }

  @include desktop {
    display: none;
  }

  @include desktopLarge {
    display: none;
  }

  @include tabletLandscape {
    display: none;
  }


  .imageContainer {
    position: relative;
    width: 100vw;
    height: 48vh;
    z-index: -1;
  }

  .TopDisplayImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imageContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
  }


  .campaign-info {
    padding: 1rem;

    .campaign-header {
      h5 {
        text-align: left;
        font-size: 0.9rem;
        font-weight: 700;
      }



      .donate-button {
        // color: gray;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .my-pledge {
      font-size: 15px;
      margin-top: 0.5rem;
      font-weight: 500;
      text-align: left;

    }

    .amount {
      font-size: 18px;
    }

    .days-left {
      font-size: 13px;
      font-weight: 500;
    }

    .campaign-details.card {
      // box-shadow: 8px 2px 12px 2px lightgrey;
      box-shadow: 1px 5px 9px 8px lightgrey;
      margin-top: 0.5rem;
      border: none;
      border-radius: 14px;
      margin-bottom: 0.8rem;
      width: 90vw;

      .card-body {
        padding-top: 0.9rem;
        padding-bottom: 0.5rem;
        padding-inline: 0.8rem;
        display: block;
      }
    }

    .progress {
      width: 100%;
      height: 7px;
      background-color: #ddd;
      margin-bottom: 0.3rem;

      .progress-fill {
        height: 100%;
        background-color: #9A1712;
        width: 53%;
        border-radius: 20px;
      }
    }

    .profileImage {
      width: 8vw;
      height: 4vh;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 5px;
    }

    .about {
      h5 {
        text-align: left;
        font-size: 17px;
        margin-top: 5px;
        font-weight: 700;
      }

      p {
        text-align: left;
        font-size: 14px;
        color: black;
        opacity: 80%;

      }

    }

    .originator {
      h5 {
        text-align: left;
        font-size: 17px;
        font-weight: 800;
      }

      span {
        font-size: 14px;
        align-self: center;
      }
    }



  }
}

.chequeNumber {
  height: 7vh;
  border-radius: 5px;
  outline: none;
  border: 1px solid lightgray;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
  font-size: 13px;
}

.pieChartContainerView {
  background: white;
  box-shadow: rgba(12, 12, 46, 0.15) 2px 3px 10px 4px;
  border-radius: 10px;
  zoom: 0.9;

  .d-flex.justify-content-between {
    padding: 1rem;
  }
}


.actions {
  @include tablet {
    display: none;
  }

  display: flex;
  position: fixed;
  bottom: 0;
  background-color: white;

  button {
    margin: 0.5rem;
    border-radius: 14px;
    font-weight: bold;
    height: 7vh;
    width: 46vw;
  }
}

.btn-danger {
  background-color: #9A1712 !important;
}

.text-danger {
  color: #9A1712 !important;
}

.tasks {
  margin-top: 0rem;
  background-color: white;
  padding: 1rem;
  zoom: 0.9;
  border-radius: 20px;
  box-shadow: rgba(12, 12, 46, 0.15) 10px 11px 25px 1px;

  @include phone {
    margin-bottom: 3rem;
  }

  .table {
    tbody {
      td.w-10 {
        width: 15%;

        img {
          border-radius: 50%;
          width: 18%;
        }
      }

      td {
        .btn.btn-sm.btn-success.text-white {
          background-color: var(--EmeraldGreen-color);
          border: none;
        }

      }

      td.landlord {
        color: var(--EmeraldGreen-color);
      }

      td.agent {
        color: #8e44ad;
      }

      td.caretaker {
        color: #f39c12;
      }


    }
  }
}


.ModeofPayment {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 6vh;
  border: 1px solid lightgray;
  padding: 0.5rem;
  border-radius: 10px;
  margin-inline: 0.5rem;

  img {
    width: 30%;
    margin-right: 0.3rem;
  }

  p {
    margin-top: 1rem;
    font-size: 11px;
  }
}

.selectedPayment {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 6vh;
  border: 1.5px solid #9A1712;
  // background-color: #f07b778d;
  padding: 0.5rem;
  border-radius: 10px;
  margin-inline: 0.5rem;

  img {
    width: 30%;
    margin-right: 0.3rem;
  }

  p {
    margin-top: 1rem;
    font-size: 11px;
  }
}

.AmountInputData {
  border-bottom: 1.5px solid #9A1712 !important;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  button {
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    background: #9A1712;
    color: white;
    font-weight: bold;
  }

  span {
    font-size: large;
    font-style: italic;
  }
}

.campaign-header-mobile {
  position: relative;
  padding: 1rem;
  background: rgb(255 255 255 / 88%);
  color: black !important;
  width: 83%;
  display: block;
  float: right;
  margin-top: -8rem;
  text-align: left;
  right: -6%;
  border-top-left-radius: 20px;
  // border-top-right-radius: 10px;
}

.opacityImage {
  width: 100vw;
  height: 48vh;
  background-color: rgb(29 41 62 / 29%);
  position: absolute;
  top: -13%;

}

.statusPledge {
  color: white;
  padding: 6px;
  border-radius: 5px;
  font-size: 12px;
  text-transform: capitalize;
  margin-left: 0.5rem;
}

// ol {
//   counter-reset: my-counter;
//   list-style: none;

//   li::before {
//     content: counter(my-counter) ". ";
//     counter-increment: my-counter;
//   }
// }


.TopRightArrow {
  font-size: 2rem;
  font-weight: 800;
  margin-left: 1rem;
  position: absolute;
  top: 7%;
  left: 0;
  color: white;
}

.ReedemMpesa {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-bottom: 1rem;
}



// .grayscale-to-color {
//   filter: grayscale(100%);
//   position: relative;
//   animation: colorize 10s; 
//   // animation: colorize 10s forwards 1s; 
// }

// @keyframes colorize {
//   0% {
//     filter: grayscale(100%);
//     clip-path: inset(0 100% 0 0);
//   }
//   100% {
//     filter: grayscale(0%);
//     clip-path: inset(0 0 0 0);
//   }
// }





