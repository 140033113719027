@import 'MediaQueries';

@include phone {
    .reportIssue {
        .container {
            .card {
                border: none;
                margin-bottom: 5rem;

                p {
                    font-size: 15px;
                    font-weight: 600;
                }

                .custom-file {
                    border: 1px solid lightgray;
                }

                label.custom-file-label {
                    display: flex;
                    place-content: center;
                }

                .TopDisplayGroups {
                    button {
                        width: fit-content;
                        border-radius: 15px;
                        padding: 12px;
                        font-size: 15px;

                    }
                }

                .CategoryButtons {
                    button {
                        margin-bottom: 1rem;
                        margin-right: 1rem;
                        border-radius: 10px;
                        padding: 10px;

                    }
                }
            }
            .BottomButtons {
                position: fixed;
                // bottom: 10px;
                // right: 10px;
                bottom: 70px;
                right: 40px;
                border-radius: 15px;
            }
        }
    }
}