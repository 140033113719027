// Define mixins for media queries

@mixin desktop {
  @media all and (min-width: 1101px) {
    @content;
  }
}

@mixin desktopMedium {
  @media all and (min-width: 1201px) {
    @content;
  }
}

@mixin desktopLarge {
  @media all and (min-width: 2000px) {
    @content;
  }
}

@mixin tabletLandscape {
  @media all and (min-width: 1000px) and (max-width: 1100px) {
    @content;
  }
}

@mixin tablet {
  @media all and (min-width: 901px) and (max-width: 1000px) {
    @content;
  }
}
@mixin miniTablet {
  @media all and (min-width: 601px) and (max-width: 900px) {
    @content;
  }
}

@mixin phone {
  @media all and (max-width: 600px) {
    @content;
  }
}

@mixin phoneSmall {
  @media all and (max-width: 300px) {
    @content;
  }
}

@mixin mobile {
  @media only screen {
    @content;
  }
}

@mixin mobileOnly {
  @media only screen and (max-width: 40em) {
    @content;
  }
}

@mixin mediumScreens {
  @media only screen and (min-width: 40.063em) {
    @content;
  }
}

@mixin mediumTablet {
  @media only screen and (min-width: 40.063em) and (max-width: 64em) {
    @content;
  }
}

@mixin largeScreens {
  @media only screen and (min-width: 64.063em) {
    @content;
  }
}

@mixin largeScreensMax {
  @media only screen and (min-width: 64.063em) and (max-width: 90em) {
    @content;
  }
}

@mixin xlargeScreens {
  @media only screen and (min-width: 90.063em) {
    @content;
  }
}

@mixin xlargeScreensMax {
  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
    @content;
  }
}

@mixin xxlargeScreens {
  @media only screen and (min-width: 120.063em) {
    @content;
  }
}