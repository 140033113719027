.selectedPledge {
    .pledgeDisplay {
        .TopDisplayImage {
            width: 87vw;
            height: 60vh;
            // object-fit: cover;
        }

        .ownerPledgeData {
            position: absolute;
            top: 16%;
            margin-left: 6rem;
            text-align: left;
            color: white;
            background: rgba(43, 2, 2, 0.35);
            width: 30vw;
            border-radius: 10px;
            padding-top: 1rem;
            padding-inline: 0.4rem;

            .userNameData {
                font-size: 18px;
                font-weight: bolder;
                text-shadow: 0px 1px 2px black;
            }

            h2 {
                font-size: 9px;
                margin-top: 5px;
                text-shadow: 0px 1px 2px black;
                font-weight: bolder;
                margin-bottom: 0.2rem;
                // margin-left: 2rem;

            }



            .countDownDisplay {
                color: white;
                margin-top: -0.5rem;

                span {
                    text-shadow: 0px 1px 2px black;
                    font-size: 12px;

                }

                p {
                    text-shadow: 0px 1px 2px black;
                    font-size: 12px;
                }
            }
        }

        .ButtonsPledge {
            position: absolute;
            top: 33%;
            margin-left: 6rem;
            text-align: left;
            width: 30vw;
            border-radius: 10px;
            padding-top: 1rem;
            padding-inline: 0.4rem;

            button {
                border-radius: 15px;
            }

            img {
                width: 1.5vw;
                margin-right: 0.2rem;
            }
        }

        .pledgeTypeDisplay {
            text-transform: capitalize;
            top: 10%;
            left: 99px;
            margin-left: 0rem;
            position: absolute;
            padding: 0.5rem;
            color: white;
            border-radius: 10px;
            font-size: 10px;
        }

        .pieChartContainerView {
            display: flex;
            justify-content: space-between;
            height: 35vh;
            width: 20vw;

            .pie-chart-container {
                width: 100%;

                .apexcharts-canvas {
                    position: absolute;
                    margin-top: 1rem;
                    right: 6%;
                }
            }

            .pledgeSummary {
                width: 70%;
                text-align: left;
                margin-top: 1rem;

                p {
                    font-size: 12px;
                    margin-bottom: 0;
                    font-weight: 800;
                }

                span {
                    font-size: 12px;
                }
            }

            .tablistView {


                .tabView {}

            }

        }
    }

    ul.tablistView.nav.nav-tabs {
        border: 0;
        color: black;
    }

    li.nav-item {
        font-size: 16px;
        text-align: center;
        width: 30%;
        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        margin-bottom: 0.5rem;
        display: flex;

        .nav-link {
            font-size: 14px;
            color: black;
            text-align: center;
            width: 95%;
            margin-left: 0.3rem;
            border-radius: 0;
            margin-bottom: 0.5rem;
            border: 0;
            display: flex;
        }

        .nav-link:hover {
            background-color: transparent;
            color: #9A1712;
            border-bottom: 1.5px solid #9A1712;
        }

        .nav-link:active {
            background-color: transparent;
            color: #9A1712 !important;
            border-bottom: 1.5px solid #9A1712 !important;
        }

        .tabView {
            color: black !important;

        }
    }

    li.nav-item:hover {
        background-color: transparent;
        color: #9A1712;
    }

    li.nav-item.active {
        background-color: transparent;
        color: #9A1712;
    }



}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: transparent;
    color: #9A1712 !important;
    border-bottom: 1.5px solid #9A1712 !important;
    border: 0;
    border-radius: 0;

}

.nav-tabs .nav-link {
    background-color: transparent;
    color: black !important;
    border: 0;
    border-radius: 0;
}

.nav-item:hover {
    font-size: 16px;
    color: #9A1712;
    text-align: center;
    width: 110%;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    display: flex;
}

// .nav-link.active {
//     background-color: transparent;
//     color: white;
//     border-radius: 9px;
// }

.nav-item:hover {
    color: #9A1712 !important;
    background-color: transparent;
}

.tabViewActive {
    button {
        background-color: transparent;
        color: #9A1712 !important;
        border-bottom: 1.5px solid #9A1712 !important;
    }

}
.d-flex.nav.nav-tabs {
    border: none;
}