@import 'MediaQueries';

.homePage {
    overflow-x: hidden;

    @include phone {

        height: 90vh;
    }
}

.originator {
    margin-bottom: 4rem;
}

.termsandConditions {
    height: 70vh;
    overflow: auto;
}