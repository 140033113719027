.campaingOngoing {
    @media screen and (max-width: 768px) {
        display: none;
    }

    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: start;

    h2.text-danger {
        font-weight: 800;
        font-size: 1.5rem;
    }

    h3 {
        margin-top: 0.5rem;
        text-align: left;
        font-size: 1.2rem;
    }

    .dateTimeGetSpan {
        display: flex;
        width: 100%;
        color: #000000de;
        margin-top: -10px;
        font-size: 13px;

        span {
            margin-right: 0.5rem;
        }
    }

    .sliderForCampaign {
        width: 30vw;

        img {
            width: 29vw;
            height: 25vh;
            object-fit: cover;
            border-radius: 15px;
            padding-inline: 0.5rem;
        }

        .slick-slide.slick-active.slick-current {
            background: white;
        }

        button.slick-arrow.slick-prev {
            background: #9A1712;
            border-radius: 50%;
            position: absolute;
        }

        .slick-prev:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1.15;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        button.slick-arrow.slick-next {
            background: #9A1712;
            border-radius: 50%;
            position: absolute;
        }

        .slick-next:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1.15;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

}

.mobileCampaingOngoing {
    @media screen and (min-width: 768px) {
        display: none;
    }

    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: start;

    h2.text-danger {
        font-weight: 800;
        font-size: 1.5rem;
    }

    h3 {
        margin-top: 0.5rem;
        text-align: left;
        font-size: 1.2rem;
    }

    .dateTimeGetSpan {
        display: flex;
        width: 100%;
        color: #000000de;
        margin-top: -10px;
        font-size: 13px;

        span {
            margin-right: 0.5rem;
        }
    }

    .sliderForCampaign {
        width: 80vw;
        margin:0 auto;

        img {
            width: 80vw;
            height: 30vh;
            object-fit: cover;
            border-radius: 5px;
            padding-inline: 0.5rem;
        }

        .slick-slide.slick-active.slick-current {
            background: white;
        }

        button.slick-arrow.slick-prev {
            background: #9A1712;
            border-radius: 50%;
            position: absolute;
        }

        .slick-prev:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1.15;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        button.slick-arrow.slick-next {
            background: #9A1712;
            border-radius: 50%;
            position: absolute;
        }

        .slick-next:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1.15;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

}