@import 'MediaQueries';

.activePledges {
    @include phone {
        display: none;
    }

    .truncate-text-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 126px;
    }

    margin-top: 0.5rem;
    margin-bottom: 2rem;
    width: 90%;
    margin-left: 0.2rem;

    // @include tablet {
    //     width: 85%;
    //     margin-left: 1.2rem;
    // }
    @include tabletLandscape {
        width: 85%;
        margin-left: 1.2rem;
    }

    h2.text-danger {
        font-weight: 800;
        font-size: 1.1rem;
    }

    h3 {
        text-align: left;
        font-size: 1.2rem;
    }

    .dateTimeGetSpan {
        display: flex;
        width: 77.5%;
        color: #000000de;
        margin-top: -10px;
        font-size: 13px;

        span {
            margin-right: 0.5rem;
        }
    }

    .sliderForCampaign {
        display: flex;
        flex-flow: wrap;

        .activePledgesCards.card {
            // padding-inline: 0.5rem;
            width: 100% !important;
            height: 40vh;
            // width: 85% !important;
            border: none;
            box-shadow: 1px 2px 2px lightgray;
        }

        .activePledgeTitle {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 0rem;
        }

        .profileUserImage {
            background-color: transparent;
            display: flex;
            justify-content: space-between;
            position: absolute;
            width: 12vw;
            top: 1%;
            left: 5%;

            @include tabletLandscape {
                width: 17vw;
            }

            @include tablet {
                width: 17vw;
            }
            @include miniTablet {
                width: 30vw;
            }

            img {
                // border-radius: 50%;
                // height: 5vh;
                // width: 5vh;
                // padding-inline: 0;
                border-radius: 50%;
                height: 25px;
                width: 25px;
                padding-inline: 0;
                margin-bottom: 30px;
                @include tabletLandscape {
                    height: 3vh;
                    width: 3vh;
                }

                @include tablet {
                    height: 3vh;
                    width: 3vh;
                }

                // position: absolute;
                // left: 170px;
                // top: 2px;
            }

            span {
                height: 3vh;
                font-size: 8px;
                margin-left: 0.5rem;

                @include tablet {
                    height: 2vh;
                }
                @include desktop {
                    height: 3vh;
                }

                @include miniTablet {
                    height: 2vh;
                }

                @include tabletLandscape {
                    height: 2vh;
                }
            }
        }

        .pledegActiveDisplayData {
            padding: 0.5rem;
            width: fit-content;
        }

        .topImageCampaign {
            width: 14vw;
            height: 25vh;
            object-fit: cover;
            border-radius: 15px;
            padding-inline: 0.5rem;
            border-radius: 15px 15px 0px 0px;

            @include tabletLandscape {
                width: 20vw;
                height: 15vh;

            }
            @include miniTablet {
                width: 35vw;


            }
        }

        .slick-slide.slick-active.slick-current {
            background: white;
        }

        button.slick-arrow.slick-prev {
            background: #9A1712;
            border-radius: 50%;
            position: absolute;
            left: -6%;

        }

        .slick-prev:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1.15;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        button.slick-arrow.slick-next {
            background: #9A1712;
            border-radius: 50%;
            position: absolute;
            right: -6%;
        }

        .slick-next:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1.15;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .nameofUserHere {
            background: rgba(253, 249, 249, 0.9);
            color: black;
            text-align: left;
            width: 91%;
            margin-top: -1.85rem;
            margin-left: 0.5rem;
            z-index: 1020;
            position: relative;
            height: 5vh;
            // border-radius: 0px 0px 10px 10px;

            p {
                padding-left: 0.5rem;
                padding-top: 0.3rem;
                font-size: 12px;
                font-weight: 400;
            }

            // box-shadow: 1px -7px 7px #f5f5f5c9;
        }
    }

}

.mobileActivePledges {   

    @include phone {
        display: block;
    }

    @include miniTablet {
        display: none;
    }
    @include tablet {
        display: none;
    }
    @include tabletLandscape {
        display: none;
    }
    @include desktop {
        display: none;
    }
    @include desktopLarge {
        display: none;
    }
    

    .imageDisplay {
        width: 10%;
        height: 2vh;
        position: relative;
        bottom: 6px;
        margin-right: 7px;
    }

    .bottomButtonsDisplay {
        position: fixed;
        width: 95vw;
        bottom: 5px;
        left: 10px;
        background: white;
        z-index: 10000000;

        .plusCircleButton {
            width: 10%;
            height: fit-content;
            place-self: center;
        }
    }

    .truncate-text-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 126px;
    }

    margin-top: 0.5rem;

    margin-bottom: 2rem;

    h2.text-danger {
        font-weight: 800;
        font-size: 1.5rem;
    }

    h3 {
        text-align: left;
        font-size: 1.2rem;
    }

    .searchBarMobile {

        input.form-control {
            outline-color: transparent;
            border: 2px solid lightgray;
            border-radius: 0px;
            width: 94vw;
            padding: 0.7rem;
            margin-left: 0.5rem;
            border-radius: 20px;
        }
    }

    .dateTimeGetSpan {
        display: flex;
        width: 77.5%;
        color: #000000de;
        margin-top: -10px;
        font-size: 13px;

        span {
            margin-right: 0.5rem;
        }
    }

    .sliderForCampaignMobile {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin-bottom: 10rem;

        .activePledgesCards.card {
            padding-inline: 0.5rem;
            margin-inline: 1rem;
            width: 45vw;
            margin-top: 1.5rem;
            // width: 85% !important;
            border: none;
            box-shadow: 1px 2px 2px lightgray;

            @include phone {

                margin-inline: 0.5rem;
                height: 29vh;
            }

        }

        .activePledgeTitle {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 0rem;
        }

        .profileUserImage {
            background-color: transparent;
            display: flex;
            justify-content: space-between;
            position: absolute;
            width: 28vw;
            top: 1%;
            right: 0%;
            justify-content: right;

            img {
                border-radius: 50%;
                height: 5vh;
                width: 5vh;
                padding-inline: 0;
                // position: absolute;
                // left: 170px;
                // top: 2px;
            }

            span {
                height: 3vh;
                font-size: 8px;
                margin-left: 0.5rem;
            }
        }

        .pledegActiveDisplayData {
            padding: 0.5rem;
            width: fit-content;
            margin-top: 0.6rem;

            @include phone {
                margin-top: 0;

            }
        }

        .topImageCampaign {
            width: 45vw;
            // width: 14vw;
            height: 15vh;
            object-fit: cover;
            border-radius: 15px;
            padding-inline: 0.5rem;
            border-radius: 15px 15px 0px 0px;
        }

        .slick-slide.slick-active.slick-current {
            background: white;
        }

        button.slick-arrow.slick-prev {
            background: #9A1712;
            border-radius: 50%;
            position: absolute;
            left: -6%;

        }

        .slick-prev:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1.15;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        button.slick-arrow.slick-next {
            background: #9A1712;
            border-radius: 50%;
            position: absolute;
            right: -6%;
        }

        .slick-next:before,
        .slick-next:before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1.15;
            opacity: .75;
            color: white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .nameofUserHere {
            background: rgba(253, 249, 249, 0.9);
            color: black;
            text-align: left;
            width: 100%;
            margin-top: -1.85rem;
            margin-left: 0.5rem;
            z-index: 1020;
            position: relative;
            height: 5vh;
            // border-radius: 0px 0px 10px 10px;

            p {
                padding-left: 0.5rem;
                padding-top: 0.3rem;
                font-size: 14px;
                font-weight: 500;
            }

            // box-shadow: 1px -7px 7px #f5f5f5c9;
        }
    }

}

.topSpan {
    position: absolute;
    top: -25px;
    right: 0px;
}

.searchBar {
    border: 2px solid #9A1712;
    border-radius: 10px;
    position: relative;
    right: -103px;
    @include miniTablet {
        right: -50px;

    }

    input.form-control {
        font-size: 12px;
    }
}