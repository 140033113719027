// Mixin for creating 3D bubbles
@import 'MediaQueries';

@mixin bubble($size, $top, $right, $animation-delay) {
    position: absolute;
    width: $size;
    height: $size;
    top: $top;
    right: $right;
    //   background-color: $primary-color; #d88687 ba90b0 
    // background: radial-gradient(circle at center, $primary-color, #d88687);
    background: linear-gradient(to right, #d88687 17%, #ba90b0 51%, #9A1712 65%);

    border-radius: 50%;
    // animation: bubbleAnimation 10s infinite linear;
    animation-delay: $animation-delay;
    z-index: -1;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2); // Add inner shadow for 3D effect
}

$primary-color: #9A1712;

.mainRegister {
    height: 100vh;
    overflow: hidden;

}

.fullnameerror {

    color: #9A1712;
    font-size: 10px;
    position: relative;
    float: right;
    margin-bottom: 5px;
    margin-top: -10px;
    padding-top: -24px;

}


.RegisterPage {
    background-color: rgb(208 35 29 / 0%);
    height: 100vh;
    overflow: hidden;


    @include phone {

        display: none;
    }

    .topNameDisplayRegister {
        margin-left: 2.5rem;
        text-align: left;
        margin-top: 4rem;

        @include miniTablet {
            margin-left: 1.5rem !important;
        }

        @include tablet {
            margin-left: 1rem;
            margin-top: 6rem;

        }

        @include desktop {
            margin-top: 1rem;
            margin-left: 2.5rem;

        }

        @include desktopLarge {
            margin-top: 1rem;
            margin-left: 8rem;

        }
    }

    .loginForm {
        text-align: left;
        background: #ffffff47;
        border-radius: 20px;
        height: 60vh;
        padding: 1rem;
        width: 40vw;
        // margin-top: 1rem;
        margin-left: 7rem;

        @include tablet {
            width: 50vw;
            margin-left: 0;

        }

        @include miniTablet {
            margin-left: 0.5rem;
            width: 50vw;

        }

        // box-shadow: 3px 3px 8px #bd7d7d;
        // animation: shadowAnimation 1.5s infinite alternate;
        label {
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        input.form-control {
            margin-bottom: 1rem;
            height: 7vh;
            width: 27vw;

            @include tablet {
                width: 40vw;

            }

            @include miniTablet {
                width: 45vw;
            }
        }

        h2 {
            justify-self: center;
            font-size: 2.5rem;
        }

        a {
            color: $primary-color;
            text-decoration: none;
            font-weight: 700;
            justify-self: right;
            // text-shadow: 0px 2px 2px black;
            font-size: 15px;

            b {
                text-shadow: none;

            }

        }

        a.forgotLink {
            float: right;
        }

        button {
            width: 100%;
            justify-self: center;
            border-radius: 20px;
            height: 6vh;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
        }
    }

    // Styling for bubbles


    .passwordValidation {
        p {
            margin-bottom: 0
        }
    }

    .bubble6 {
        @include bubble(1000px, -40px, 1000px, 13s);
    }

    .curvedSpan {
        // background: $primary-color;
        background-image: url('../img/family_image.JPG');
        width: 60vw;
        height: 115vh;
        border-radius: 50%;
        margin-top: -8rem;
        margin-left: 9rem;
        background-size: cover;
        background-repeat: no-repeat;

        @include tablet {
            margin-left: 2rem;
        }

        @include miniTablet {
            margin-left: 0.5rem;
        }


        .textDisplay {
            color: white;
            zoom: 0.9;

            .InnerDivDisplay {
                position: relative;
                background-color: rgb(255 255 255 / 30%);
                backdrop-filter: blur(20px);
                height: 60vh;
                top: 20%;
                position: absolute;
                width: 35vw;
                margin-left: 5rem;
                border-radius: 10px;
                padding: 1rem;

                @include tablet {
                    height: fit-content;
                    width: fit-content;
                }

                @include miniTablet {
                    zoom: 0.6;
                    height: fit-content;
                    width: 70vw;
                    margin-left: 3rem;


                }

                // border: 1.5px solid white;
            }

            h2 {
                padding-top: 0rem;
                color: #9A1712;
                // padding-top: 12rem;
                text-align: left;
                padding-left: 1rem;
                // padding-left: 6rem;
                width: 100%;
                font-weight: 800;
                font-size: 1.7rem;

            }

            p {
                padding-top: 0rem;
                text-align: left;
                padding-left: 1rem;
                // padding-left: 6rem;
                width: 100%;
                color: black;


            }

        }
    }

    .spanarrow {
        background: #9A1712;
        border-radius: 20px;
        color: white;
        margin-top: 0.4rem;
        height: 6vh;
        margin-right: 6px;

        i {
            padding: 1rem;
            margin-top: 1rem;
            font-size: 28px;
        }
    }

    .selectChurch {
        display: flex;
        justify-content: space-between;
        margin-top: 0rem;
        margin-bottom: 1rem;

        .church-select {
            height: 6vh;
            width: 27vw;
            margin-top: 0.4rem;
            margin-right: 0.5rem;
        }
    }

}

.chooseButtonRegister {
    margin-top: 1rem;

}

.registerIndividual {
    display: flex;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: -1rem;

    .InactiveButton {
        width: 30%;
        border: none;
        border: 1.5px solid#9A1712;
        color: black;
        background-color: transparent;
        height: 9vh !important;
        margin-left: 1rem;
        border-radius: 10px !important;

    }

    .InactiveButto:hover {
        background-color: #9A1712;
        color: white;
    }

}

.ActiveButton {
    background-color: #9A1712 !important;
    color: white !important;
    border: none;

    i {
        color: white !important;

    }
}

.progress {
    width: 75%;
    height: 8.5px;
    background-color: #ddd;
    margin-bottom: 0.3rem;
    margin-right: 5px;

    .progress-fill {
        height: 100%;
        background-color: #9A1712;
        width: 53%;
        border-radius: 20px;
    }
}


.pageCounter {
    font-size: 14px;
    width: 40%;

}

.phoneContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .nationCode {
        height: 3rem;
        width: 5rem;
        background-color: white;

        p {
            font-size: 27px;
            color: black;
            font-weight: 700;
        }
    }
}

.RegisterPageMobile {
    background-color: rgb(208 35 29 / 0%);
    height: 100vh;
    overflow: hidden;
    overflow-x: hidden;

    @include phone {
        display: block;
    }

    @include tablet {
        display: none;
    }

    @include miniTablet {
        display: none;
    }

    @include tabletLandscape {
        display: none;
    }

    @include desktopLarge {
        display: none;
    }

    @include desktop {
        display: none;
    }

    img {
        float: left;
        width: 25%;
    }

    .loginForm {
        display: grid;
        width: 85%;
        text-align: left;
        margin: 0 auto;
        margin-top: 5%;
        padding: 0.5rem;
        background: rgb(255 255 255 / 69%);
        border-radius: 20px;
        height: 56vh;

        @include phone {

            height: 65%;
        }

        // animation: shadowAnimation 1.5s infinite alternate;
        .spanarrow {
            background: #9A1712;
            border-radius: 12px;
            color: white;
            margin-top: 0.8rem;
            height: 8vh;
            margin-right: 6px;
            font-size: 2.5rem;

            i {
                padding: 1rem;
                margin-top: 1rem;
                font-size: 28px;
            }
        }

        h2 {
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 0rem;
        }

        label {
            font-weight: 700;
            font-size: 16px;
        }

        input {
            height: 7vh;
            border-radius: 12px;
            border: 1px solid #9A1712;
            margin-top: -1rem;


            @include phone {

                margin-top: 1rem;
                width: 75vw;
                height: 8vh;
            }
        }


        a {
            color: black;
            text-decoration: none;
            justify-self: right;
            font-weight: 800;
            place-self: center;
            margin-top: 1rem;
            font-size: 20px;
            margin-bottom: 0.2rem;
        }

        button {
            width: 100%;
            height: 8.2vh;
            border-radius: 12px;
            justify-self: center;
            margin-top: 10px;
            margin-bottom: -9%;

        }



    }



    // Styling for bubbles
    .bottomRegister {
        position: absolute;
        bottom: -13%;
        left: 0;
        right: 0;
        width: 100vw;

        a {
            display: flex;
            justify-content: center;
        }

        button {
            margin-inline: 1rem;
            width: 90%;
            height: 4.5rem;

        }
    }

    .bubble6 {
        @include bubble(310px, 90px, -50px, 13s);
    }

    .bubble5 {
        @include bubble(150px, 550px, 297px, 13s);
    }


}