@import 'MediaQueries';

.donation {
    padding-top: 4.5rem;
    width: 100vw;
    text-align: left;
    overflow-x: hidden;
    margin-inline: 0.4rem;

    @include tablet {
        width: 65vw;
    }

    @include tabletLandscape {
        width: 65vw;
    }

    @include desktop {
        width: 65vw;
    }

    @include desktopLarge {
        width: 65vw;
    }

    .ModeofPayment {
        display: flex;
        align-items: center;
        width: -moz-fit-content;
        width: fit-content;
        height: 5vh;
        border: 1.5px solid black;
        padding: 0.5rem;
        border-radius: 12px;
        margin-inline: 0.5rem;

        img {
            width: 20%;
        }

        p {
            font-size: 13px;
        }
    }

    .bankTransfer img {
        width: 5%;
    }

    .selectedPayment {
        display: flex;
        align-items: center;
        width: -moz-fit-content;
        width: fit-content;
        height: 5vh;
        border: 3px solid #9A1712 !important;
        padding: 0.5rem;
        border-radius: 12px;
        margin-inline: 0.5rem;


        p {
            font-weight: 900;
            font-size: 13px;


        }
    }

    p {
        font-size: 12px;
        font-weight: 600;
    }

    input {
        border-top: 0;
        padding-left: 24rem;
        font-size: 2.2rem;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        outline-color: transparent;
    }

    .d-flex {
        margin-top: 1rem;

        .custom-button {
            width: 29%;
            border-radius: 20px;
            margin-inline: 0.2rem;
            margin-bottom: 1rem;
            border: none;
            color: black;
            background: lightgray;
            font-weight: bold;
        }


    }

    .kshP {
        margin-bottom: 0;
        left: 26%;
        position: absolute;
        margin-top: 1.5rem;

    }


}

.footerButton {
    position: fixed;
    bottom: 0px;
    left: 0;

    button {
        background-color: #9A1712;
        border: none;
        width: 95vw;
        margin-inline: 0.6rem;
        height: 7vh;
        margin-bottom: 1%;
        border-radius: 15px;
    }
}

input.input-field.fieldData {
    width: 100%;
    margin-bottom: 0;
    padding: 0.3rem;
    border: 1.5px solid transparent;
    border-radius: 5px;
    outline-color: transparent;
    font-size: 13px;
    width: 95%;
    height: 7vh;
    background: rgb(128 128 128 / 21%);
    border: none;
}


// button{
//     width: 100vw;
//     position: fixed;
//     bottom: 0;
// }