@import 'MediaQueries';

.NewHomePage {
    display: block;
    padding: 1rem;

    @include phone {

        padding: 0rem;
    }

    .pledgeDisplay {
        width: 88%;
        right: 0;
        position: absolute;
        overflow-x: hidden;

        @include phone {
            display: none;
        }

        @include miniTablet {
            width: 100%;

            .container,
            .container-sm {
                max-width: 100%;
            }
        }

        @media (min-width: 770px) and (max-width: 900px) {
            width: 80%;

        }




        @include tablet {
            width: 88%;
        }

        .BottomDisplayCard {
            margin-top: 1rem;
            border: none;

            .pledgeProgressPiePledge {
                width: 50%;
                text-align: center;

                // width: 100%;
                @include tablet {
                    width: 75%;
                }


                .apexcharts-canvas {
                    position: absolute;
                    margin-top: 0rem;
                    right: 34%;

                    @include tablet {
                        left: -55%;
                    }

                    @include miniTablet {
                        right: -20%;
                    }

                    @include tabletLandscape {
                        right: -35%;
                    }

                    @include desktop {
                        right: -50px;
                    }

                    @include desktopMedium {
                        right: 0px;
                        position: relative;
                        display: flex;
                        // right: -25px;
                    }

                    @media (min-width: 750px) and (max-width: 900px) {
                        right: -55%;

                    }

                }

                .percentage-text {
                    position: absolute;
                    // top: 12vw;
                    top: 52%;
                    // left: 52%;
                    right: 0%;
                    // right: 44%;
                    transform: translate(-50%, -50%);
                    font-size: 1.5rem;
                    font-weight: bold;

                    @include tablet {
                        top: 20vw;
                    }

                    @include desktopLarge {
                        top: 32%;
                        // left: 52%;
                        right: 0%;
                    }

                    @include desktop {
                        top: 32%;
                        // left: 52%;
                        right: 0%;
                    }

                    @include miniTablet {
                        right: 0%;
                        top: 55%;

                    }

                    @media (min-width: 750px) and (max-width: 900px) {
                        right: 0;
                        top: 165px;

                    }
                }
            }

            .pledgeProgressPie {
                width: 60%;
                text-align: center;


                // width: 100%;
                @include tablet {
                    width: 65%;


                    .apexcharts-canvas {
                        position: absolute;
                        margin-top: 0rem;
                        right: 14%;

                        @include tablet {
                            left: -70%;
                        }

                        @include miniTablet {
                            right: 55%;
                        }


                    }


                }

                .percentage-text {
                    position: absolute;
                    top: 52%;
                    right: 0%;
                    transform: translate(-50%, -50%);
                    font-size: 1.5rem;
                    font-weight: bold;

                    @include tablet {
                        top: 20vw;
                    }

                    @include miniTablet {
                        right: 0%;
                        top: 55%;

                    }

                    @include desktopLarge {
                        right: 19vw;
                        top: 17vh;
                    }

                    @media (min-width: 750px) and (max-width: 900px) {
                        right: 0;
                        top: 165px;

                    }
                }


            }

            .campaignProgressPie {
                width: 50%;
                text-align: center;
                // width: 100%;

                .apexcharts-canvas {
                    position: absolute;
                    margin-top: 0rem;
                    right: -4%;

                    @include tablet {
                        left: -95%;
                        width: 55%;

                    }

                    @include desktop {
                        right: -50%;
                    }

                    @include miniTablet {
                        right: -30%;
                    }

                    @include tabletLandscape {
                        right: -55%;
                    }

                    @include desktopMedium {
                        right: -55px;
                        margin-top: 3px;
                    }


                    @media (min-width: 750px) and (max-width: 900px) {
                        right: -85%;

                    }
                }

                .percentage-text {
                    position: absolute;
                    top: 52%;
                    right: 5.5%;
                    transform: translate(-50%, -50%);
                    font-size: 1.5rem;
                    font-weight: bold;

                    @include tablet {
                        top: 20vw;
                        right: 5%;

                    }

                    @include phone {
                        top: 20vw;
                        right: 5%;

                    }

                    @include miniTablet {
                        left: 0%;
                        top: 55%;

                    }

                    @include desktopLarge {
                        top: 17vh;
                        right: 4vw;
                    }

                    @media (min-width: 750px) and (max-width: 900px) {
                        // left: 65% !important;
                        top: 165px;

                    }
                }
            }
        }

        .TopDisplayButtons {
            button {
                width: max-content;
            }
        }

        h1 {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 1rem;
            color: white;
            position: absolute;
            z-index: 1;
            // background:transparent
            top: 170px;
            left: 3%;
            text-shadow: 2px 2px 3px black;

            @include tablet {
                top: 190px;
                left: 6%;

            }

        }

        .categoryCampaign {
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 1rem;
            color: white;
            position: absolute;
            top: 15%;
            left: 5%;
            text-shadow: 2px 2px 3px black;
        }

        p {
            font-size: 0.9rem;
            line-height: 1.5;
            font-weight: 400;
        }

        .topRaised {
            position: absolute;
            top: 23%;
            // top: 32%;
            left: 3%;
            background-color: rgb(43 2 2 / 55%);
            color: white;
            padding: 1rem;
        }


        .progress-bar {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            span {
                font-size: 1.2rem;
                font-weight: bold;
                margin-right: 1rem;
            }


        }



        .give-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;

            .give-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #ddd;
                padding: 1rem;
                width: calc(50% - 1rem);

                p {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-bottom: 0.5rem;
                }

                span {
                    font-size: 1.2rem;
                }
            }
        }

        .doughnut-chart {
            width: 150px;
            height: 150px;
            margin: 0 auto;
        }

        a {
            font-size: 1.2rem;
            font-weight: bold;
            padding: 0.5rem;
            // background-color: #4caf50;
            color: #fff;
            text-decoration: none;
            border-radius: 4px;
            transition: background-color 0.3s ease;

            &:hover {
                // background-color: #3e8e41;
            }
        }

        .TopDisplayImage {
            width: 100vw;

            height: 55vh;
            object-fit: cover;

        }

        .image-container::after {
            content: "";
            background: rgb(32 32 32 / 28%);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            height: 55vh;

            width: 100%;

        }

        .leftFixedPledgeData {
            position: fixed;
            right: 0;
            top: 60%;

        }

        .TopDisplayCard {
            border: none;
            box-shadow: 1px 0px 9px rgb(43 2 2 / 55%);
            margin-top: -5rem;
            background-color: #7c0000a8;

            .card-body {
                display: flex;

                @include miniTablet {
                    zoom: 0.8;
                }
            }

        }





        .pie-chart-campaign {
            .percentage-text {
                position: absolute;
                top: 32%;
                right: -2%;
                transform: translate(-50%, -50%);
                font-size: 1.5rem;
                font-weight: bold;
            }
        }

        .campaignDescription {
            padding: 1rem;
            overflow-y: auto;
            height: 50vh;
            border: none;
            box-shadow: 2px 2px 3px 2px lightgray;
            border-radius: 5px;

            @include tablet {
                height: 25vh;
            }



            @include tabletLandscape {
                height: 50vh;
            }

            @include miniTablet {
                height: fit-content;

            }



            @media (min-width: 750px) and (max-width: 900px) {
                height: 35vh;

            }

        }

        .campaignPledgeTotal {
            padding: 1rem;
            width: 0vw;
        }

        .divPledgeProgress {
            border-right: 1px solid lightgray;
            margin-top: 25px;
            height: 23vh
        }

        .campaignPieProgress {
            background: white;
            box-shadow: 2px 2px 3px 2px lightgray;
            border-radius: 5px;
            height: 50vh;

            @include miniTablet {
                height: fit-content;
                margin-top: 1rem;
            }

            @media (min-width: 770px) and (max-width: 900px) {
                margin-top: 0;
                height: 35vh;
                zoom: 0.9;
            }

            @include tablet {
                height: 35vh;
            }




        }

    }


    .pledeDisplayMobile {
        max-height: 80vh;

        @include phone {
            display: block;
        }

        @include miniTablet {
            display: none;
        }

        @include tablet {
            display: none;
        }

        @include tabletLandscape {
            display: none;
        }

        @include desktopLarge {
            display: none;
        }

        @include desktop {
            display: none;
        }

        .TopDisplayImage {
            // width: 100%;
            // height: 30vh;
            // border-bottom-right-radius: 30px;
            // border-bottom-left-radius: 30px;
            width: 105%;
            height: 40vh;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }

        .campaign-info {
            padding: 2%;
            margin-top: 1rem;

            .campaign-header {
                h5 {
                    text-align: left;
                    // font-size: 0.9rem;
                    font-size: 1.2rem;
                    font-weight: 700;
                }



                .donate-button {
                    color: gray;
                    font-size: 14px;
                    font-weight: 100;
                }
            }

            .my-pledge {
                font-size: 15px;
                margin-top: 0.5rem;
                font-weight: 500;
                text-align: left;

            }

            .amount {
                font-size: 18px;
            }

            .days-left {
                font-size: 13px;
                font-weight: 500;
            }

            .campaign-details.card {
                // box-shadow: 8px 2px 12px 2px lightgrey;
                box-shadow: 1px 5px 9px 8px lightgrey;
                // margin-top: 0.5rem;
                border: none;
                border-radius: 14px;
                // margin-bottom: 0.8rem;
                margin: 0 auto;

                .card-body {
                    padding-top: 0.9rem;
                    padding-bottom: 0.5rem;
                    padding-inline: 0.8rem;
                    display: block;
                }
            }

            .progress {
                width: 100%;
                height: 7px;
                background-color: #ddd;
                margin-bottom: 0.3rem;

                .progress-fill {
                    height: 100%;
                    background-color: #9A1712;
                    width: 53%;
                    border-radius: 20px;
                }
            }

            .profileImage {
                width: 8vw;
                height: 4vh;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 5px;
            }

            .about {
                h5 {
                    text-align: left;
                    font-size: 17px;
                    margin-top: 5px;
                    font-weight: 700;
                }

                p {
                    text-align: left;
                    font-size: 14px;
                    color: black;
                    opacity: 80%;

                }

            }

            .originator {
                h5 {
                    text-align: left;
                    font-size: 17px;
                    font-weight: 800;
                }

                span {
                    font-size: 14px;
                    align-self: center;
                }
            }



        }
    }

    .actions {
        @include phone {

            display: block;
        }

        @include tablet {

            display: none;
        }

        display: flex;
        position: fixed;
        bottom: 0;
        background-color: white;

        button {
            margin: 0.5rem;
            border-radius: 14px;
            font-weight: bold;
            height: 7vh;
            width: 45vw;

            @include phoneSmall {
                width: auto;

            }
        }
    }

    .btn-danger {
        background-color: #9A1712 !important;
    }

    .text-danger {
        color: #9A1712 !important;
    }

    @include phone {
        .GraphCardsDisplay {
            margin-bottom: 8rem;

            .card {
                width: 45vw;
                border: none;
                box-shadow: 1px 4px 6px 3px lightgray;
                border-radius: 10px;
            }

            span {
                // top: 20vw;
                // position: absolute;
                // right: 35%;
                // font-size: 14px;
            }
        }

        .percentage-text {
            position: relative;
            bottom: 0;
            color: #8e44ad;
            // right: -45%;
        }

        .apexcharts-canvas {
            position: relative;
            margin-bottom: 1rem;
            user-select: none;
            // right: 25%;
        }
    }


    .tasks {
        margin-top: 0rem;
        background-color: white;
        padding: 1rem;
        zoom: 0.9;
        border-radius: 20px;
        box-shadow: rgba(12, 12, 46, 0.15) 10px 11px 25px 1px;

        .table {
            tbody {
                td.w-10 {
                    width: 15%;

                    img {
                        border-radius: 50%;
                        width: 18%;
                    }
                }

                td {
                    .btn.btn-sm.btn-success.text-white {
                        background-color: var(--EmeraldGreen-color);
                        border: none;
                    }

                }

                td.landlord {
                    color: var(--EmeraldGreen-color);
                }

                td.agent {
                    color: #8e44ad;
                }

                td.caretaker {
                    color: #f39c12;
                }


            }
        }
    }
}

.photoOptional {
    width: 30vw !important;

    @include phone {

        width: 90vw !important;
    }
}

.topCounterPledge {
    background: #f39c12;
    color: white;
    padding: 0.2rem;
    border-radius: 50%;
    position: absolute;
    top: 35%;
    right: 4%;
    font-size: 13px;
    height: 4vh;
    width: 4vh;
    transform: translate(21px, 5px);

    @include tablet {
        height: 3vh;
        width: 3vh;
    }

    @include tabletLandscape {
        top: 40%;

    }




    @include miniTablet {
        top: 38%;
        right: 5%;
        height: 3vh;
        width: 3vh;
    }

    @include phone {
        background: #9A1712;
        height: 3vh;
        width: auto;
        padding-inline: 8px;
        top: 20%;
        right: 9%;


    }

    @media (min-width: 750px) and (max-width: 900px) {
        top: 33%;
        right: 4%;
    }
}

  
.shareButtons {

    @include phone {

        width: 44vw !important;
        font-size: 9px;

        img {
            height: 3vh !important;
        }
    }
}

.churchButtonDisplay {
    border-radius: 5px;
    font-size: 11px;
    margin-left: 6px;
    width: 40vw;
    margin-top: 10px;


}

.churchBadgedRedeem {
    width: 12%;
    object-fit: cover;
    rotate: 25deg;
    height: 4%;
    margin-left: auto;
    margin-top: -7px;
    margin-bottom: 4px;
}

.givingBlackText {
    background-color: black;
    color: white;
    padding-inline: 10px;
    text-align: center;

    width: fit-content;
    margin: 0 auto;
    border-radius: 5px;
}

select.kshInput {
    @include phone {

        font-size: 11px;
    }
}