.badgeModal {
    h3 {
        font-weight: 700;
    }

    .custom-badge {
        background-color: var(--badge-color) !important;
        font-size: 13px;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 30px;
        margin-top: 5px;
    }

    .modal-body {
        padding-top: 0;
        margin-top: -45px;
        z-index: 0;
        position: relative;
    }

    .modal-header {
        border: 0;
        z-index: 2;
        position: relative;
    }

    .modal-content {
        border: 2px solid var(--badge-color);
        width: 90%;
        margin: 0 auto;
    }

    .modal-footer {
        display: block;
        align-self: center;
        border: 0;
        margin-top: -30px;
    }
}