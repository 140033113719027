// Define custom styles
$primary-color: #9A1712; // Red color for bubbles
$background-color: yellow; // Yellow background color for page
@import 'MediaQueries';

// Mixin for creating 3D bubbles
@mixin bubble($size, $top, $left, $animation-delay) {
    position: absolute;
    width: $size;
    height: $size;
    top: $top;
    left: $left;
    background: linear-gradient(to right, #9A1712 75%, #ba90b0 30%, #d88687 11%);
    border-radius: 50%;
    animation-delay: $animation-delay;
    z-index: -1;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
}

// Keyframes for bubble animation
@keyframes bubbleAnimation {
    0% {
        transform: translateX(100%) translateY(0);
    }

    100% {
        transform: translateX(-100%) translateY(0);
    }
}

@keyframes shadowAnimation {
    0% {
        box-shadow: 0 0 20px #b86a6a;
        filter: blur(0px);
    }

    100% {
        box-shadow: 0 0 20px #b86a6a;
        filter: blur(5px);
    }
}

// Styling for the login form and page background
.mainLogin {
    height: 100vh;
    overflow: hidden;

}

.LoginPage {
    background-color: rgb(208 35 29 / 0%);
    height: 100vh;
    overflow: hidden;

    @include phone {

        display: none;
    }

    .loginForm {
        text-align: left;
        margin: 0 auto;
        background: #ffffff47;
        border-radius: 20px;
        height: 80vh;
        padding: 1rem;
        width: 30vw;
        margin-top: 2rem;

        @include miniTablet {
            margin: 1px;
            height: fit-content;
            width: 50vw;
            zoom: 0.8;
            margin-top: 7rem;


        }

        @include tablet {
            margin-top: 9rem;
            width: 45vw;

        }

        label {
            font-weight: 600;
            margin-bottom: 0.5rem;

            @include tablet {
                font-size: 11px;
            }
        }

        input.form-control {
            margin-bottom: 1rem;
            height: 7vh;
            width: 27vw;

            @include tablet {
                font-size: 11px;
                width: 40vw;

            }
            @include miniTablet {
                font-size: 11px;
                width: 40vw;

            }
        }

        h2 {
            justify-self: center;
            font-size: 2.5rem;

            @include tablet {
                font-size: 1.5rem;
            }
        }

        a {
            color: $primary-color;
            text-decoration: none;
            font-weight: 700;
            justify-self: right;
            // text-shadow: 0px 2px 2px black;
            font-size: 15px;

            @include tablet {
                font-size: 10px
            }

            b {
                text-shadow: none;

            }

        }

        a.forgotLink {
            float: right;
        }

        button {
            width: 100%;
            justify-self: center;
            border-radius: 20px;
            height: 6vh;
            margin-bottom: 1rem;
            margin-top: 0.5rem;

            @include tablet {
                height: 5vh;
                font-size: 10px
            }
        }
    }

    // Styling for bubbles



    .bubble6 {
        @include bubble(1000px, -40px, 1000px, 13s);
    }

    .curvedSpan {
        // background: $primary-color;
        background-image: url('../img/family_image.JPG');
        width: 60vw;
        height: 110vh;
        border-radius: 50%;
        margin-top: -8rem;
        margin-left: 9rem;
        background-size: cover;
        background-repeat: no-repeat;

        .textDisplay {
            color: white;
            zoom: 0.9;

            .InnerDivDisplay {
                position: relative;
                background-color: rgb(255 255 255 / 30%);
                backdrop-filter: blur(20px);
                height: 70vh;
                top: 20%;
                position: absolute;
                width: 35vw;
                margin-left: 5rem;
                border-radius: 10px;
                padding: 1rem;
                // border: 1.5px solid white;
            }

            h2 {
                padding-top: 0rem;
                color: #9A1712;
                // padding-top: 12rem;
                text-align: left;
                padding-left: 1rem;
                // padding-left: 6rem;
                width: 100%;
                font-weight: 800;
                font-size: 1.7rem;

            }

            p {
                padding-top: 0rem;
                text-align: left;
                padding-left: 1rem;
                // padding-left: 6rem;
                width: 100%;
                color: black;


            }

        }
    }

}

// Define custom styles
$primary-color: #9A1712; // Red color for bubbles

// Mixin for creating 3D bubbles
@mixin bubble($size, $top, $right, $animation-delay) {
    position: absolute;
    width: $size;
    height: $size;
    top: $top;
    right: $right;
    //   background-color: $primary-color; #d88687 ba90b0 
    // background: radial-gradient(circle at center, $primary-color, #d88687);
    background: linear-gradient(to right, #d88687 17%, #ba90b0 51%, #9A1712 65%);

    border-radius: 50%;
    // animation: bubbleAnimation 10s infinite linear;
    animation-delay: $animation-delay;
    z-index: -1;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2); // Add inner shadow for 3D effect
}

// Keyframes for bubble animation
@keyframes bubbleAnimation {
    0% {
        transform: translateX(100%) translateY(0);
    }

    100% {
        transform: translateX(-100%) translateY(0);
    }
}

@keyframes shadowAnimation {
    0% {
        box-shadow: 0 0 20px #b86a6a;
        filter: blur(0px);
    }

    100% {
        box-shadow: 0 0 20px #b86a6a;
        filter: blur(5px);
    }
}

// Styling for the login form and page background


.LoginPageMobile {
    background-color: rgb(208 35 29 / 0%);
    height: 100vh;
    overflow: hidden;
    overflow-x: hidden;

    @include phone {


        display: none;
    }

    img {
        float: left;
        width: 25%;
    }

    .loginForm {
        display: grid;
        width: 85%;
        text-align: left;
        margin: 0 auto;
        margin-top: 10rem;
        padding: 2rem;
        background: rgb(255 255 255 / 69%);
        border-radius: 20px;
        height: 50vh;
        // animation: shadowAnimation 1.5s infinite alternate;


        h2 {
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 0rem;
        }

        label {
            font-weight: 700;
            font-size: 16px;
        }

        input {
            height: 9vh;
            border-radius: 12px;
            border: none;
            margin-top: -1rem;
        }


        a {
            color: black;
            text-decoration: none;
            justify-self: right;
            font-weight: 800;
            place-self: center;
            margin-top: 1rem;
            font-size: 20px;
            margin-bottom: 0.2rem;
        }

        button {
            width: 100%;
            height: 8.2vh;
            border-radius: 12px;
            justify-self: center;

        }
    }

    // Styling for bubbles
    .bottomRegister {
        position: absolute;
        bottom: 1%;
        left: 0;
        right: 0;
        width: 100vw;

        a {
            display: flex;
            justify-content: center;
        }

        button {
            margin-inline: 1rem;
            width: 100%;
            height: 4.5rem;

        }

        span {
            font-size: 13px;
            text-align: center !important;
            display: block;
            width: 92%;
            margin-top: 0.5rem;
        }
    }

    .bubble6 {
        @include bubble(310px, 90px, -50px, 13s);
    }


}

.btn-dark {
    background-color: black;
    border: black;
}