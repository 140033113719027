.mobileAirtime {
    padding: 1rem;
    width: 95%;
    overflow-x: hidden;
    font-family: 'Axiforma', sans-serif;

    .mobileAirtimeHeader {
        h2 {
            font-weight: 800;
            font-size: 26px;
            margin-bottom: 1rem;
            font-family: 'Axiforma', sans-serif;
        }
    }

    .mobileAirtimeBody {
        label {
            font-size: 18px;
            margin-bottom: 0.5rem;
        }

       input.form-control {
            padding: 1.2rem;
            margin-bottom: 0.5rem;
            background-color: rgba(0, 0, 41, 0.062745098);
            border: 0;
            margin-bottom: 30px;
            font-weight: bold;
            font-size: 24px;
        }
    }

    button {
        padding: 0.5rem;
        font-size: 14px;
        font-weight: 800;
        height: 7vh;
        border-radius: 10px;
        margin-top: 0.5rem;
    }
}