/* breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1150px',
  } */
@import 'MediaQueries';

.SideBar {
    position: absolute;
    z-index: 10;

    @media screen and (max-width: 576px) {
        height: 100%;
    }
}

/* the design for the side bar that contains the navigations */
.sidebar {
    position: fixed;
    top: -47px;
    margin-top: 3rem;
    left: -150px;
    height: 100%;
    border-right: 1px solid #9A1712;
    width: 150px;
    z-index: 1;
    overflow-y: scroll;
    padding: 0;
    transition: left 0.3s ease-in-out;
    overflow-x: hidden;

    @media screen and (min-width: 700px) {
        z-index: 10;
        background-color: white;
        top: 0px;


    }

    @media screen and (max-width: 576px) {
        position: relative;
    }
}

.sidebar.show {
    left: 0;
}

/* the design that contains the kodi logo on the side bar */
.navbar {
    background-color: white;
    width: 150px;
    place-content: center;
    border-right: 1px solid #9A1712;
    position: fixed;

    @media screen and (max-width: 576px) {
        width: 65% !important;
        overflow-x: hidden;
    }
}

.navbar-brand {
    font-weight: bold;
    font-size: 15px;
    color: #006132;
    font-family: "Fonarto", sans-serif;

    @media screen and (max-width: 576px) {
        display: none;
    }
}

.navbar-text {
    font-size: 1rem;
}


.nav-link {
    font-size: 14px;
    color: #9A1712;
    text-align: center;
    width: 95%;
    margin-left: 0.3rem;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    display: flex;
}

.nav-item {
    font-size: 16px;
    color: #9A1712;
    text-align: center;
    width: 110%;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    display: flex;
}

.nav-link i {
    margin-right: 0.7rem;
}

.nav-link:hover {
    background-color: #9A1712;
    color: white;
    border-radius: 9px;
}

.nav-link.active {
    background-color: #9A1712;
    color: white;
    border-radius: 9px;
}

.nav-item:hover {
    background-color: #9A1712;
    color: white;
    border-radius: 9px;
}

.nav-item.active {
    background-color: #e9ecef;
    color: #343a40;
    border-radius: 9px;
}

.signout {
    position: fixed;
    bottom: 0;
    width: 10.5%;

    @include phone {
        bottom: 20px;

    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}


/* Track */
::-webkit-scrollbar-track {
    border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #9A1712;
    border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #9A1712;
}

button.btn.sidebar-toggle.navbar-toggler-icon {
    display: none !important;

    @media only screen and (max-width: 768px) {
        display: none !important;
    }
}

/* media queries */
@media only screen and (max-width: 768px) {
    .navbar {
        width: 100%;
        justify-content: left;
        position: fixed;
        margin-bottom: 1rem;
        margin-top: 0rem;
        z-index: 1050;
        margin-left: 0 !important;
        background: transparent;
        border-right: 0px solid #9A1712;
    }

    .sidebar {
        margin-top: 7rem;
        left: -150px;
        max-height: 100vh;
        border-right: 0px solid #9A1712;
        width: 150px;
        background-color: white;
        z-index: 1020;
        overflow-y: hidden;
        padding: 20px;
        transition: left 0.3s ease-in-out;
        overflow-x: hidden;
        position: fixed;

        @include phone {
            padding: 5px;

        }

    }
}

@media (max-width: 600) {
    .sidebar {
        width: 150px;
        z-index: 1020;
        background-color: white;
    }

    .navbar button.btn.sidebar-toggle {
        display: block;
        background: blue;
        width: 10rem;
        height: 10rem;
        border: none;
    }
}

@media (min-width: 600) {
    .sidebar-toggle {
        position: absolute;
        left: 0;
        width: 50px;
        height: 50px;
        border: none;
        display: none !important;
    }
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    padding: 3px 5px;
    width: 61px;
}