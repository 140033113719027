@import 'MediaQueries';

.sharedPledgeCard {
    .pledgeDisplay {
        width: 100%;
        right: 0;
        position: relative;
        overflow-x: hidden;

        @include phone {

            display: none;
        }

        .react-tel-input .form-control {

            width: 465px !important;
        }

        .BottomDisplayCard {
            margin-top: 1rem;
            border: none;

            .pledgeProgressPie {
                width: 60%;
                // width: 100%;

                .apexcharts-canvas {
                    position: absolute;
                    margin-top: -1.3rem;
                    right: -2%;
                }

                .percentage-text {
                    position: absolute;
                    top: 35px !important;
                    right: 60px !important;
                    transform: translate(-50%, -50%);
                    font-size: 1rem;
                    font-weight: bold
                }
            }


            .campaignProgressPie {
                width: 50%;
                // width: 100%;

                .apexcharts-canvas {
                    position: absolute;
                    margin-top: -1.3rem;
                    right: -10%;
                }

                .percentage-text {
                    position: absolute;
                    top: 32% !important;
                    right: 10% !important;
                    transform: translate(-50%, -50%);
                    font-size: 1rem;
                    font-weight: bold;
                }
            }
        }

        h1 {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 1rem;
            color: white;
            position: absolute;
            background: transparent;

            top: 18%;
            left: 3%;
            text-shadow: 2px 2px 3px black;

        }

        .categoryCampaign {
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 1rem;
            color: white;
            position: absolute;
            top: 15%;
            left: 5%;
            text-shadow: 2px 2px 3px black;
        }

        p {
            font-size: 0.9rem;
            line-height: 1.5;
        }

        .topRaised {
            position: absolute;
            top: 27%;
            // top: 32%;
            left: 3%;
            background-color: rgb(43 2 2 / 55%);
            color: white;
            padding: 1rem;
        }

        .react-tel-input .form-control {
            position: relative;
            font-size: 14px;
            letter-spacing: .01rem;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            padding-left: 48px;
            margin-left: 0;
            background: #FFFFFF;
            border: 1px solid #CACACA;
            border-radius: 5px;
            line-height: 25px;
            height: 35px;
            width: 468px;
            outline: none;
        }


        .progress-bar {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            span {
                font-size: 1.2rem;
                font-weight: bold;
                margin-right: 1rem;
            }


        }



        .give-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;

            .give-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #ddd;
                padding: 1rem;
                width: calc(50% - 1rem);

                p {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-bottom: 0.5rem;
                }

                span {
                    font-size: 1.2rem;
                }
            }
        }

        .doughnut-chart {
            width: 150px;
            height: 150px;
            margin: 0 auto;
        }

        a {
            font-size: 1.2rem;
            font-weight: bold;
            padding: 0.5rem 2rem;
            background-color: #4caf50;
            color: #fff;
            text-decoration: none;
            border-radius: 4px;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #3e8e41;
            }
        }

        .TopDisplayImage {
            width: 100vw;
            // width: 87vw;

            height: 60vh;
            object-fit: cover;

        }

        .TopDisplayImage::before {
            content: "";
            background-color: rgba(29, 41, 62, 0.6);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;


        }

        .leftFixedPledgeData {
            position: fixed;
            right: 0;
            top: 60%;

        }

        .TopDisplayCard {
            border: none;
            box-shadow: 1px 0px 9px rgb(43 2 2 / 55%);
            margin-top: -5rem;
            background-color: #7c0000a8;

            .card-body {
                display: flex;
            }

        }





        .pie-chart-campaign {
            .percentage-text {
                position: absolute;
                top: 32%;
                right: -2%;
                transform: translate(-50%, -50%);
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
    }

    .pledeDisplayMobile {
        max-height: 80vh;

        @include tablet {

            display: none;
        }

        @include tabletLandscape {

            display: none;
        }

        @include desktop {

            display: none;
        }

        @include desktopLarge {

            display: none;
        }

        .TopDisplayImage {
            width: 100%;
            height: 30vh;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
        }

        .campaign-info {
            padding: 1rem;

            .campaign-header {
                h5 {
                    text-align: left;
                    font-size: 0.9rem;
                    font-weight: 700;
                }



                .donate-button {
                    color: gray;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .my-pledge {
                font-size: 15px;
                margin-top: 0.5rem;
                font-weight: 500;
                text-align: left;

            }

            .amount {
                font-size: 18px;
            }

            .days-left {
                font-size: 13px;
                font-weight: 500;
            }

            .campaign-details.card {
                // box-shadow: 8px 2px 12px 2px lightgrey;
                box-shadow: 1px 5px 9px 8px lightgrey;
                margin-top: 0.5rem;
                border: none;
                border-radius: 14px;
                margin-bottom: 0.8rem;

                .card-body {
                    padding-top: 0.9rem;
                    padding-bottom: 0.5rem;
                    padding-inline: 0.8rem;
                    display: block;
                }
            }

            .progress {
                width: 100%;
                height: 7px;
                background-color: #ddd;
                margin-bottom: 0.3rem;

                .progress-fill {
                    height: 100%;
                    background-color: #9A1712;
                    width: 53%;
                    border-radius: 20px;
                }
            }

            .profileImage {
                width: 8vw;
                height: 4vh;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 5px;
            }

            .about {
                h5 {
                    text-align: left;
                    font-size: 17px;
                    margin-top: 5px;
                    font-weight: 700;
                }

                p {
                    text-align: left;
                    font-size: 14px;
                    color: black;
                    opacity: 80%;

                }

            }

            .originator {
                h5 {
                    text-align: left;
                    font-size: 17px;
                    font-weight: 800;
                }

                span {
                    font-size: 14px;
                    align-self: center;
                }
            }



        }
    }

    .actions {
        @include tablet {

            display: none;
        }

        @include tabletLandscape {

            display: none;
        }

        @include desktop {

            display: none;
        }

        @include desktopLarge {

            display: none;
        }

        display: flex;
        position: fixed;
        bottom: 0;
        background-color: white;

        button {
            margin-left: 0.5rem;
            border-radius: 14px;
            font-weight: bold;
            height: 7vh;
            width: 95vw;
            border: none;
        }
    }

    .btn-danger {
        background-color: #9A1712 !important;
    }

    .text-danger {
        color: #9A1712 !important;
    }

    .tasks {
        margin-top: 0rem;
        background-color: white;
        padding: 1rem;
        zoom: 0.9;
        border-radius: 20px;
        box-shadow: rgba(12, 12, 46, 0.15) 10px 11px 25px 1px;

        .table {
            tbody {
                td.w-10 {
                    width: 15%;

                    img {
                        border-radius: 50%;
                        width: 18%;
                    }
                }

                td {
                    .btn.btn-sm.btn-success.text-white {
                        background-color: var(--EmeraldGreen-color);
                        border: none;
                    }

                }

                td.landlord {
                    color: var(--EmeraldGreen-color);
                }

                td.agent {
                    color: #8e44ad;
                }

                td.caretaker {
                    color: #f39c12;
                }


            }
        }
    }
}