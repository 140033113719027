@import 'MediaQueries';

.mobilelanding {
    overflow-x: hidden;

    @include phone {

        display: block;
    }


    .truncate-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 5;
    }

    .mainImage {
        @include tablet {
            display: none;
        }

        @include miniTablet {
            display: none;
        }

        @include tabletLandscape {
            display: none;

        }

        @include desktop {
            display: none;

        }

        img {

            width: 100vw;
            height: 55vh;
            margin-top: 0rem;
            object-fit: cover;
            // object-fit: cover;
            //   clip-path: path('M 0 200 Q 100 250 200 200 L 200 0 L 0 0 Z');
        }

        .belowDivDisplay {
            background-color: white;
            position: fixed;
            height: 50vh;
            bottom: 0%;
            width: -webkit-fill-available;
            @include phone {
                text-align: center;
            }
        }

        .displayCurve {
            background-color: red;
            position: absolute;
            width: 100vw;
            bottom: 50%;
            height: 65vh;
            border-top-left-radius: 50% 20%;
            border-top-right-radius: 50% 20%;
            transform: rotate(180deg);
            clip-path: ellipse(114% 64% at center -40%);
        }

        .bottomButtons {
            position: absolute;
            bottom: 0%;
            width: 105vw;
            height: 48vh;
            display: flex;
            background: white;
            padding-inline: 0rem;
            padding-top: 0rem;
            justify-content: space-between;

            @include phone {

                height: 35vh;
            }

            // background: red;
            // clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 75%);

        }

        button {
            height: 6vh !important;
            width: 40vw;
            border: 2px solid #9A1712;
            text-align: center;
            position: relative;
            border-radius: 10px;
            color: #9A1712;
            top: 0%;
            /* Adjust this value to vertically center buttons */
            margin: auto;
            margin-bottom: 0.9rem;
            /* Center buttons horizontally */
        }

    }


    section {
        padding: 60px 0;
        position: relative;
    }

    .logo {
        position: absolute;
        top: 2%;
        left: 40%;
        right: 40%;
        // border-radius: 50%;
        width: 90px;
        height: 90px;
        z-index: 2;
        background-color: white;

        @include tablet {
            display: none;
        }

        @include miniTablet {
            display: none;
        }

        @include tabletLandscape {
            display: none;

        }

        @include desktop {
            display: none;

        }


        img {
            width: 80%;
            height: 80%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .welcomeContent {
        position: absolute;
        // top: 30%;
        top: 190px;
        left: 6%;
        right: 6%;
        width: 90%;
        height: 180px;
        z-index: 30;
        overflow-y: scroll;
        border-radius: 3%;
        background-color: rgb(255 255 255 / 35%);
        backdrop-filter: blur(20px);

        @include phone {

            backdrop-filter: blur(30px);
        }


        h2 {
            color: black;
            padding-top: 30px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: start;
            font-size: 14px;

            @include phone {

                // text-shadow: 3px 2px 3px black;
                font-size: 17px;
            }

        }

        p {
            margin: 0;
            margin-top: 10px;
            text-align: start;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
            color: black;
            font-size: 11.5px;

            @include phone {

                font-size: 12.5px;

            }
        }
    }



}
.helpDeskButton{
    display: none;
    @include phone {
        display: flex;
    }
}

.desktopLanding {

    overflow: hidden;

    @include phone {

        display: none;
    }

    .arrowlogin {
        width: 18%;
        /* height: 10vh; */
        margin-left: 6.5rem;
        transform: rotate(225deg);
    }

    .display {
        width: 34%;
        height: 4vh;
        transform: rotate(-90deg);
        margin-top: 13rem;
    }

    svg {
        transform: rotate(270deg);
        height: 100vh;
        width: 50.8vw;
        margin-top: 0rem;
        margin-left: -86%;
        position: relative;

        @include miniTablet {
            width: 160.5vw;
            right: 154%;
            margin-left: 0;
        }


        @include tablet {
            width: 150.5vw;
            right: 150%;
            margin-left: 0;

        }

        @include tabletLandscape {
            width: 150.5vw;
            right: 160%;
            margin-left: 0;
        }

        @include desktop {
            width: 89.5vw;
            right: 100%;
            margin-left: 0;
        }
    }

    .empowermentSection {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px;


        // background-color: #f5f5f5;
        // .empowermentImage {
        // width: 40vw;
        // height: 40vw;
        // object-fit: cover;
        // }

        .imageContainer {

            overflow: hidden;
            border-radius: 38% 0 0 41%;
            /* You can adjust the values above to control the curvature */
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        }

        .empowermentImage {
            display: block;
            width: 40vw;
            height: 50vw;
            object-fit: cover;
            border: 5px solid black;
        }

        .displayedCurvedDisplay {
            background-image: url('../img/family_image.JPG');
            background-size: cover;
            background-position: center;
            width: 40vw;
            height: 100vh;
            border: none;

            @include tablet {
                width: 42vw;
            }

            @include miniTablet {
                width: 42vw;
            }
        }

        .content {
            max-width: 500px;
            margin-right: 100px;
            margin: 0 auto;

            @include desktopLarge {
                max-width: 800px;

            }

            @include tablet {
                margin-right: 0;
                max-width: 48%;

            }

            @include miniTablet {
                margin-right: 0;
                max-width: 48%;

            }


            .title {
                font-size: 30px;
                line-height: 44px;
                color: #3f3d56;
                margin-bottom: 24px;

                @include desktopLarge {
                    font-size: 60px;

                }
            }

            .description {
                font-size: 18px;
                line-height: 28px;
                color: #3f3d56;
                margin-bottom: 32px;

                @include desktopLarge {
                    font-size: 25px;
                    line-height: 60px;
                }
            }

            .donateButton {
                display: inline-block;
                padding: 6px 18px;
                font-size: 12px;
                line-height: 24px;
                color: #fff;
                background-color: #9a1712;
                text-decoration: none;
                border-radius: 4px;
                transition: background-color 0.3s ease;
                position: relative;
                z-index: 2000;
                margin-left: 3.5rem;

                &:hover {
                    background-color: darken(#9a1712, 10%);
                }
            }


            .stats {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-top: 16px;

                .statItem {
                    text-align: center;

                    .statValue {
                        font-size: 20px;
                        line-height: 32px;
                        font-weight: 700;
                        color: #9a1712;

                        @include tablet {
                            font-size: auto;

                        }
                    }

                    .statLabel {
                        font-size: 16px;

                        @include tablet {
                            font-size: auto;

                        }

                    }
                }

            }
        }
    }
}

.TopHeader {
    padding-top: 5rem;
    padding-left: 2rem;
    color: #9a1712;
    width: 60vw;
    font-size: 2rem;
}

.BottomDesign {
    position: fixed;
    bottom: 0;
    display: flex;

    h2 {
        font-size: 19px;
        font-weight: bolder;
    }

    button {
        width: 90vw !important;
        height: 7vh !important;
        border-radius: 6px !important;
        font-size: 18px;
    }

    span.gradient-text {
        background: linear-gradient(to bottom right, #ff5e57, #409fff);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-weight: 800;
        font-size: 12px;
    }

}

.bg-dark {
    background-color: black !important;
}